body {
    background-color: $bgc;
    color: $fc;

    font-family: 'Play', sans-serif;
}

.centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 1px 1px 2px black;
    p {
        font-size: 3rem;
    }
}
.left {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
}
h1 {
    font-weight: 700;
    font-size: 8rem;
}
h2 {
    font-size: 4rem;
    font-weight: 700;
}

a {
    color: lighten( $fc, 10% );
    &:hover,
    &:focus{
        color: darken( $fc, 5% );
        text-decoration: none;
    }
}
