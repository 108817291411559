/*
* Third Party
*/
@import url('https://fonts.googleapis.com/css?family=Play:400,700&subset=cyrillic,cyrillic-ext,latin-ext');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import "../../bower_components/normalize.css/normalize.css";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap";

/*
* Custom
*/
@import "partials/var";
@import "partials/app";